import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    Backdrop, Box, Button, createStyles, Dialog, DialogActions, DialogTitle, FormControl, Grid, IconButton, makeStyles, Select, TextField, Theme, Typography, useMediaQuery, useTheme
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { useState } from 'react';
// Icons
import CancelIcon from '@mui/icons-material/Cancel';
import CreateIcon from '@mui/icons-material/Create';
import unmappedFile from '../../../images/Report/UnmappedFile.png';
import { useHistory } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { useGlobalState } from '../../../store/GlobalStore';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        snackbar: {
            color: 'black',
            marginTop: '-0.5cm',
        },
        snackbarfont: {
            fontSize: 16,
        },
        createReport: {
            borderRadius: '20px',
            marginTop: 5,
            marginBottom: 5,
            marginRight: 13,
            float: 'right',
            fontSize: 12,
            backgroundColor: 'green',
            '&:hover': {
                background: "green",
                color: "white",
            }
        },
        cancelRepot: {
            borderRadius: '20px',
            marginTop: 5,
            marginBottom: 5,
            marginRight: 13,
            float: 'right',
            fontSize: 12,
            backgroundColor: 'red',
            color: 'white',
            '&:hover': {
                background: "red",
                color: "white",
            }
        },
        placementTitle: {
            color: 'blue',
            fontSize: 16,
            marginTop: '5px'
        },
        placementTitle1: {
            color: 'blue',
            fontSize: 16,
            marginTop: '10px'
        },
        formControl: {
            alignItems: "left",
            '& .MuiButtonBase-root': {
                padding: 0
            }
        },
        textField: {
            width: 300,
            backgroundColor: 'white',
            float: 'left',
            fontSize: 12,
            borderRadius: '10px',
            marginTop: '1px',
            borderBottom: '3.6px solid blue',
        },
        dropdownClient: {
            width: 300,
            float: 'left',
            backgroundColor: 'white',
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 8,
            marginTop: '7px',
            borderBottom: '3.6px solid blue',
        },
        smallFont: {
            fontSize: 13
        },
        textStyle: {
            textDecoration: 'none',
            cursor: 'pointer',
            color: 'black',
            fontSize: 13,
            marginTop: '2px'
        },
        textBoxStyle: {
            marginTop: '5px',
            fontSize: 10,
            '& .MuiChip-labelSmall': {
                paddingLeft: '5px',
                paddingLight: '5px',
                fontSize: '10px'
            }
        },
        ackTextStyle: {
            fontWeight: 'bold',
            marginTop: '4px',
            color: 'white',
            fontSize: 20,
        },
        boxBorder: {
            border: '2px solid gray',
            padding: '2px 8px',
            marginTop: '10px',
            borderRadius: '5px',
            maxWidth: '100%',
            boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset'
        },
        textBoxStyle2: {
            fontSize: 10,
            '& .MuiChip-labelSmall': {
                fontSize: 10
            }
        },
        scrollMargin: {
            '& div:first-child': {
                marginBottom: '5px',
            },
            '& div:nth-child(3) div': {
                backgroundColor: '#2377e4 !important'
            },
            '& div:nth-child(3)': {
                top: '10px !important',
                bottom: '5px !important'
            },
        },
        valueContainer: {
            "& > :not(:last-child)": {
                marginRight: '5px',
                marginTop: '2px'
            },
            "& > *": {
                marginBottom: '5px',
                marginTop: '2px'
            }
        },
        clientCodeBox: {
            width: 300,
            borderRadius: '10px',
            borderBottom: '3.7px solid blue',
            marginTop: '2px'
        },
        paper2: {
            border: '2px solid black',
            height: 'auto',
            margin: 0
        },
        labelStyle: {
            fontWeight: 'bold',
            color: 'green',
            fontSize: 14,
            marginTop: '15px'
        },
        dialogePaper: {
            minWidth: "700px"
        },
        clientStyle: {
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '10px'
        },
        createStyle: {
            justifyContent: 'center',
            alignItem: 'center',
            display: 'flex',
            marginTop: '10px'
        },
        buttonborderStyle: {
            marginTop: '6px',
            backgroundColor: '#e7e7fb'
        },
        IconButtonripple: {
            '&:hover': {
                backgroundColor: 'transparent',
            }
        },
        image: {
            width: '70px',
            height: '70px',
            textAlign: 'center'
        },
        text: {
            color: "black",
            textAlign: "center",
            fontSize: '16px',
            fontWeight: 'bold',
        },
    })
);

const usePopoverStyles = makeStyles({
    paper: {
        border: '2px solid black'
    },
    dropdownClient: {
        width: 300,
        float: 'left',
        backgroundColor: 'white',
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
        marginTop: '7px',
        borderBottom: '3.6px solid blue',
    },
    smallFont: {
        fontSize: 13
    },
});

const UnmappedReport = () => {
    const theme = useTheme();
    let history = useHistory();
    const classes = useRowStyles();
    const { state } = useGlobalState();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const popoverClasses = usePopoverStyles();
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [open, setOpen] = useState(false);

    const [StartDate, setStartDate] = useState<any>(new Date().setDate(new Date().getDate() - 1));
    const [EndDate, setEndDate] = useState(new Date());
    const [selectedFolder, setSelectedFolder] = useState();

    const DateFormat = state.GlobalUtils?.settingValue === "DD/MM/YYYY" ? "dd/MM/yyyy" : state.GlobalUtils?.settingValue === "MM/DD/YYYY" ? "MM/dd/yyyy" : state.GlobalUtils?.settingValue === "YYYY/MM/DD" ? "yyyy/MM/dd" : "MM/dd/yyyy";

    const handleClose = () => {
        setOpen(false);
        setStartDate(new Date().setDate(new Date().getDate() - 1));
        setEndDate(new Date());
    };

    const handleFirstDateChange = (date: Date | null) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date: Date | null) => {
        setEndDate(date);
    };

    const handleFolder = (event, selected) => {
        setSelectedFolder(selected);
    };

    const getAckReportsAsync = () => {
        history.push('/unmappedBuilder', {
            startDate: StartDate, endDate: EndDate, selectedFolder: selectedFolder
        });
    }

    const options = [
        { label: 'PestPac', id: 3 },
        { label: 'Fieldroutes', id: 4 },
        { label: 'ServicePro', id: 5 },
        { label: 'RCC', id: 6 },
        { label: 'BlueCow', id: 7 },
    ];

    return (
        <React.Fragment>
            <Box>
                <IconButton id="unmappedReport" aria-label="unmappedReport" onClick={(_) => { setOpen(true) }} className={classes.IconButtonripple}>
                    <img src={unmappedFile} alt="unmappedReport" className={classes.image} />
                </IconButton>
                <Box>
                    <Typography variant="h6" className={classes.text} onClick={(_) => { setOpen(true) }}>
                        Unmapped Cloud File Report
                    </Typography>
                </Box>
            </Box>

            <Backdrop className={classes.backdrop} open={ProgressBar}>
                <PuffLoader size={80} color={"white"} speedMultiplier={1} />
            </Backdrop>

            <Dialog TransitionComponent={Transition}
                fullScreen={fullScreen} open={open} PaperProps={{ style: { borderRadius: 10 } }}
                aria-labelledby="responsive-dialog-title" fullWidth={true} classes={{ paper: classes.dialogePaper }} >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h5" gutterBottom className={classes.ackTextStyle}>
                        UNMAPPED CLOUD FILE REPORT
                    </Typography>
                </DialogTitleHeader>
                {moment(StartDate).format("YYYY-MM-DD") > moment(EndDate).format("YYYY-MM-DD") ?
                    <DialogTitle style={{ padding: '5px 22px 0px 25px' }}>
                        <Alert severity="error" className="snackBarStyle1">
                            End date should be greater than the Start date!
                        </Alert>
                    </DialogTitle>
                    : ""}

                <Grid container spacing={0} style={{ padding: '5px 30px' }}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" gutterBottom className={classes.placementTitle}>
                            <b> CHOOSE START DATE </b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <Typography variant="h6" gutterBottom className={classes.placementTitle} style={{ marginLeft: '20px' }}>
                            <b> CHOOSE END DATE </b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <FormControl className={classes.formControl}>
                                <KeyboardDatePicker
                                    PopoverProps={{ classes: popoverClasses }}
                                    inputVariant="outlined"
                                    variant="inline"
                                    size="small"
                                    format={DateFormat}
                                    className={classes.textField}
                                    disableToolbar
                                    autoOk={true}
                                    margin="normal"
                                    id="DocUpLog_StartDate"
                                    value={StartDate}
                                    onChange={handleFirstDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    disableFuture={true}
                                />
                            </FormControl>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <FormControl className={classes.formControl}>
                                <KeyboardDatePicker
                                    PopoverProps={{ classes: popoverClasses }}
                                    size="small"
                                    disableToolbar
                                    autoOk={true}
                                    inputVariant="outlined"
                                    variant="inline"
                                    format={DateFormat}
                                    className={classes.textField}
                                    margin="normal"
                                    id="DocUpLog_EndDate"
                                    value={EndDate}
                                    style={{ marginLeft: '20px' }}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    disableFuture={true}
                                />
                            </FormControl>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="h6" gutterBottom className={classes.placementTitle1}>
                            <b> CHOOSE ALLIANCE </b>
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            id="combo-box-demo"
                            size='small'
                            options={options?.map(option => ({ id: option.id, label: option.label }))}
                            getOptionLabel={(option) => option.label}
                            className={classes.smallFont}
                            getOptionSelected={(option, value) => option.label === value.label}
                            onChange={(e, selected) => { handleFolder(e, selected); }}
                            renderOption={(option) => (
                                <React.Fragment>
                                    {option.label}
                                </React.Fragment>
                            )}
                            renderInput={(params) => <TextField className={classes.dropdownClient} {...params} label="Select Folder" variant="outlined" />}
                        />
                    </Grid>
                </Grid>
                <DialogActions className={classes.buttonborderStyle}>
                    <Button autoFocus color="primary" size="small" variant="contained"
                        onClick={() => { getAckReportsAsync(); }} startIcon={<CreateIcon />} className={classes.createReport}>
                        Generate Report
                    </Button>
                    <Button autoFocus color="primary" size="small" variant="contained" onClick={(e) => { handleClose(); }}
                        startIcon={<CancelIcon />} className={classes.cancelRepot}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog >
        </React.Fragment >
    )
}

export default UnmappedReport
