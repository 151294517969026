import React, { useEffect, useState } from 'react';
import {
  Backdrop, Box, createStyles, Grid, makeStyles, Paper, Table,
  TableContainer, TableHead, TableRow, Theme, withStyles, TableBody, TableCell
} from '@material-ui/core';
import { Button } from '@mui/material';
import moment from 'moment';
import * as _ from 'lodash';
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../../../components/Footer/Footer';
//Icons
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import errormsg from '../../../images/WarningImage/errormsg.svg';
import DownloadIcon from '@mui/icons-material/Download';
import VideoPlayer from '../../../components/YouTubePlayer/VideoPlayer';
// Models
import { useHistory } from 'react-router-dom';
import { usePost } from "../../../utils/apiHelper";
import { ITemplateField } from '../../../models/Placements/ITemplateField';
import { IPlacementAccount } from '../../../models/Placements/IPlacementAccount';
import { GlobalStateAction, useGlobalState } from "../../../store/GlobalStore";
import { IAccountNumberList, IClientCodeDetail, IClientCodeExempted, IItemizationDateAndState, IItemizationResults, IPhonenumber, ISpecialNameList, IStateAndZipCodeList, IStateResult, IZipCodeResult } from '../../../models/Placements/IPlacementRejectRules';

const useStyles = makeStyles((theme) => ({
  searchBox: {
    marginTop: "3%",
    borderRadius: 20
  },
  container: {
    marginTop: '4px',
    alignItems: "center",
    border: '2px solid gray',
    borderRadius: 10,
    backgroundColor: 'white',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    padding: '3px',
    maxWidth: "98.9vw",
    maxHeight: 800,
    minHeight: 800,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 'calc( 100vh - 125px)',
      minHeight: 'calc( 100vh - 125px)',
    },
  },
  formControl: {
    minWidth: 280,
    marginRight: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
  },
  actions: {
    width: "100%",
    marginTop: "1cm",
    marginRight: theme.spacing(2)
  },
  buttons: {
    borderRadius: 20,
    fontSize: 11,
    fontWeight: 'bold',
    background: "#007FFF",
    color: "white",
    marginTop: "10px",
    float: 'right',
    marginRight: 10
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  div: {
    flexGrow: 1
  },
  progressBar: {
    height: '6px'
  },
  title: {
    alignContent: "center",
    marginTop: '10px',
    float: 'right'
  },
  buttonbox: {
    float: 'right',
    padding: '5px'
  },
  tableHead: {
    background: "#364F6B",
    boxShadow: '2px 1px 8px 1px rgba(103, 128, 159, 1)',
  },
  tableRow: {
    border: '1.8px solid #364F6B',
    padding: '8px',
    textIndent: '8px',
    fontSize: 11
  },
  tableRow1: {
    color: "white",
    textAlign: 'left',
    padding: '0px',
    textIndent: '10px',
    fontSize: 12
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  tablebody: {
    display: 'flex',
    marginBottom: '10px',
    backgroundColor: 'whitesmoke',
    maxHeight: 600,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 'calc( 100vh - 208px)',
    },
  },
  textWrap: {
    display: "inline-block",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "70%",
    overflow: 'hidden',
    marginTop: '8px',
    '&:hover': {
      overflow: 'visible',
      transition: '0.10s',
    },
  },
  LabelStyle: {
    float: 'left',
    marginTop: '25px',
    marginLeft: '90px'
  },
  countMsg: {
    alignContent: "left",
    display: 'flex',
    marginLeft: '10px',
    marginTop: '10px',
    color: '#945a56',
    fontWeight: 900,
    fontSize: 16
  },
  LinearBar: {
    '&.MuiLinearProgress-root': {
      height: '10px'
    },
  },
  errorContainer: {
    maxWidth: "850px",
    marginTop: '20px',
    minHeight: 450,
    borderRadius: 10,
    backgroundColor: 'white',
    border: '2px solid gray',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
  },
  divStyle: {
    padding: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: 600,
    minHeight: 600,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 'calc( 100vh - 250px)',
      minHeight: 'calc( 100vh - 250px)',
    }
  },
}))

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#364F6B',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 12,
      width: 600
    }
  }),
)(TableCell);

const PreviewData = (props) => {
  let history = useHistory();
  const classes = useStyles();
  const { state, dispatch } = useGlobalState();
  const [FinalPlacementList, setFinalPlacementList] = useState<IPlacementAccount[]>(props.location.state.placementAccountList);
  let placementAccountList: IPlacementAccount[] = props.location.state.placementAccountList;
  let filteredPlacementAccountList: IPlacementAccount[] = props.location.state.filteredPlacementAccountList;
  let selectedHeaderList: string[] = props.location.state.selectedHeaderList;
  let templateList: ITemplateField[] = props.location.state.templateList;
  let stateCodes: string[] = props.location.state.stateCodes;
  let phaseNumber: number = props.location.state.phaseNumber;
  let tablerows: number[] = props.location.state.tablerows;
  let clientCode: string = props.location.state.clientCode;
  let templateId: number = props.location.state.templateId;
  let multiCode: boolean = props.location.state.multiCode;
  const UserId = state.userAccessContext?.id;
  let IsCSVUpload = false;
  const video = '3L6IgGjIPwc';
  const title = 'A.R.M. WebView: Placing Accounts with CSV File';
  let csvUrl: any = props.location.state.csvUrl;
  let PlacementRejectedAccountsList: IPlacementAccount[] = props.location.state.PlacementRejectedAccountsList;
  let placementDuplicateAccountsList: any = props.location.state.placementDuplicateAccountsList;
  let csvBlob: any = props?.location?.state?.csvBlob;
  let filteredTableRows: number[] = props.location.state.tablerows;

  let zipCodeResult: IZipCodeResult[] = props.location?.state?.zipCodeResult;
  let stateCodeResult: IStateResult[] = props.location?.state?.stateCodeResult;
  let phoneNumberResult: IPhonenumber[] = props.location?.state?.phoneNumberResult;
  let accountNumberList: IAccountNumberList[] = props.location?.state?.accountNumberList;
  let isClientCodeExempt: IClientCodeExempted = props.location?.state?.isClientCodeExempt;
  let accountNumberResult: IAccountNumberList[] = props.location?.state?.accountNumberResult;
  let rejectNvNmStatusCode: IClientCodeDetail[] = props.location?.state?.rejectNvNmStatusCode;
  let specialNameListResult: ISpecialNameList[] = props.location?.state?.specialNameListResult;
  let itemizationDateList: IItemizationDateAndState[] = props.location?.state?.itemizationDateList;
  let delinquencyDateList: IItemizationDateAndState[] = props.location?.state?.delinquencyDateList;
  let stateAndZipCodeResult: IStateAndZipCodeList[] = props.location?.state?.stateAndZipCodeResult;
  let rejectDupicateAcResult: IAccountNumberList[] = props.location?.state?.rejectDupicateAcResult;
  let itemizationDateStatueDate: IItemizationResults[] = props.location?.state?.itemizationDateStatueDate;
  let delinquencyDateStatueDate: IItemizationResults[] = props.location?.state?.delinquencyDateStatueDate;
  let existingAccountNumberList: IAccountNumberList[] = props.location?.state?.existingAccountNumberList;

  let totalRejectedAccounts: number = (PlacementRejectedAccountsList?.length || 0) + (placementDuplicateAccountsList?.length || 0);

  if (totalRejectedAccounts != 0) {
    filteredTableRows = filteredTableRows?.slice(0, filteredTableRows?.length - totalRejectedAccounts);
  }

  const totalRecords = (PlacementRejectedAccountsList?.length || 0) + (placementDuplicateAccountsList?.length || 0);

  const handleBackClick = () => {
    history.push('/fieldnames', {
      placementAccountList: placementAccountList, tablerows: tablerows, clientCode: clientCode, multiCode: multiCode,
      phaseNumber: phaseNumber, templateId: templateId, selectedHeaderList: selectedHeaderList, stateCodes: stateCodes,
      zipCodeResult: zipCodeResult, stateCodeResult: stateCodeResult, phoneNumberResult: phoneNumberResult, accountNumberList: accountNumberList,
      accountNumberResult: accountNumberResult, rejectNvNmStatusCode: rejectNvNmStatusCode, specialNameListResult: specialNameListResult,
      itemizationDateList: itemizationDateList, delinquencyDateList: delinquencyDateList, stateAndZipCodeResult: stateAndZipCodeResult,
      rejectDupicateAcResult: rejectDupicateAcResult, itemizationDateStatueDate: itemizationDateStatueDate, delinquencyDateStatueDate: delinquencyDateStatueDate,
      isClientCodeExempt: isClientCodeExempt, existingAccountNumberList: existingAccountNumberList
    });
  };

  const getPhaseString = (phaseNum: number) => {
    switch (+phaseNum) {
      case 1:
        return "1STP";
      case 2:
        return "PREC";
      case 3:
        return "CONT";
      default:
        return "";
    }
  }

  useEffect(() => {
    let finalPlacementList = _.cloneDeep(filteredPlacementAccountList);
    finalPlacementList?.forEach(x => {
      x.delinquency_date !== null && x.delinquency_date !== "" ? x.delinquency_date = (moment(x.delinquency_date)).format('MM/DD/YYYY') : null
      x.Itemization_Date !== null && x.Itemization_Date !== "" ? x.Itemization_Date = (moment(x.Itemization_Date)).format('MM/DD/YYYY') : null
      x.Codebtor_BirthDate !== null && x.Codebtor_BirthDate !== "" ? x.Codebtor_BirthDate = (moment(x.Codebtor_BirthDate)).format('MM/DD/YYYY') : null
      x.last_payment_date !== null && x.last_payment_date !== "" ? x.last_payment_date = (moment(x.last_payment_date)).format('MM/DD/YYYY') : null
      x.service_date !== null && x.service_date !== "" ? x.service_date = (moment(x.service_date)).format('MM/DD/YYYY') : null
      x.birth_date !== null && x.birth_date !== "" ? x.birth_date = (moment(x.birth_date)).format('MM/DD/YYYY') : null
    });
    setFinalPlacementList(finalPlacementList);
  }, [])


  async function handleSubmit() {
    let finalPlacementList = _.cloneDeep(filteredPlacementAccountList);
    finalPlacementList?.map(x => {
      x.client_code = clientCode;
      x.delinquency_date !== null && x.delinquency_date !== "" ? x.delinquency_date = moment(x.delinquency_date).format('YYYY-MM-DD') : null
      x.Itemization_Date !== null && x.Itemization_Date !== "" ? x.Itemization_Date = moment(x.Itemization_Date).format('YYYY-MM-DD') : null
      x.Codebtor_BirthDate !== null && x.Codebtor_BirthDate !== "" ? x.Codebtor_BirthDate = moment(x.Codebtor_BirthDate).format('YYYY-MM-DD') : null
      x.last_payment_date !== null && x.last_payment_date !== "" ? x.last_payment_date = moment(x.last_payment_date).format('YYYY-MM-DD') : null
      x.service_date !== null && x.service_date !== "" ? x.service_date = moment(x.service_date).format('YYYY-MM-DD') : null
      x.birth_date !== null && x.birth_date !== "" ? x.birth_date = moment(x.birth_date).format('YYYY-MM-DD') : null
    });

    if (+phaseNumber != 4) {
      finalPlacementList.map(x => { x.phase = getPhaseString(phaseNumber); });
    }

    try {
      // If the Account is Submited need to save the Rejected File to Blob
      if (PlacementRejectedAccountsList?.length) {
        const formData = new FormData();
        formData.append("userid", state?.userAccessContext?.id.toString());
        formData.append("ContainerName", "placement-rejected-files-uat");
        formData.append("clientCode", clientCode);
        formData.append("phaseCode", getPhaseString(phaseNumber));
        formData.append("files", csvBlob, `Rejected_Accounts_Details-${clientCode}-${getPhaseString(phaseNumber)}-${moment(new Date()).format("MM-DD-YYYY")}.csv`);
        generateCSVFile(formData);
      }

      await usePost(`Placements/ClientUpload`, finalPlacementList).then((clientUpload) => {
        if (clientUpload.status !== 202) {
          InsertManualUploadLog();
          dispatch({ type: GlobalStateAction.Error, error: clientUpload.statusText });
        }
        history.push(`/finish`, {
          IsCSVUpload: IsCSVUpload, placementAccountList: finalPlacementList, csvBlob: props?.location?.state?.csvBlob, manualCSVRejectedUrl: props?.location?.state?.csvUrl,
          PlacementRejectedAccountsList: PlacementRejectedAccountsList, placementDuplicateAccountsList: placementDuplicateAccountsList
        });
      });
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Idle });
      dispatch({ type: GlobalStateAction.Error, error: ex });
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle });
    }
  };

  const generateCSVFile = (formData: FormData) => {
    (async () => {
      await usePost<any>("Placements/UploadRejectedFiles", formData);
    })()
  }

  const InsertManualUploadLog = () => {
    (async () => {
      try {
        let request = {
          "file_name": "",
          "fileSize": null,
          "uploadMethod": "1",
          "rid": "",
          "userid": UserId.toString(),
          "clientId": clientCode === "-2" ? "MULC" : clientCode,
          "phase": getPhaseString(phaseNumber) === undefined ? "MULP" : getPhaseString(phaseNumber),
          "bloburi": "",
          "containerName": "",
          "subFolderId": 0
        };
        dispatch({ type: GlobalStateAction.Busy });
        await usePost<any>("Placements/InsertclientuploadlogData", request);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Idle });
        dispatch({ type: GlobalStateAction.Error, error: ex });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }

  return (
    <React.Fragment>
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={state.busy ? true : false}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Grid container justify="flex-start" alignContent="flex-start" alignItems="center" spacing={0}>
          <Container fixed className={classes.container} component="div">
            <Box display="flex" flexWrap="wrap">
              <Box width="14%" p={1}>
                <Typography variant="h6" color="secondary" gutterBottom className={`${classes.title}`}>
                  <b>3. PREVIEW DATA</b>
                </Typography>
              </Box>
              {placementAccountList?.length ?
                <Box width="15%" p={1}>
                  <Typography variant="h6" gutterBottom className={classes.countMsg}>
                    <b>Total no.of Accounts: </b>
                    <span style={{ color: "green", fontWeight: 800 }}>{(placementAccountList?.length)}</span>
                  </Typography>
                </Box>
                : null}
              <Box width="25%" p={1} >
                <div>
                  {csvUrl != null ?
                    <Button style={{ marginTop: '10px' }} id="CSVPD_submit_btn" size="small" fullWidth startIcon={<DownloadIcon />} variant="outlined" sx={{ mt: 1 }} className="blinkButton"
                      color="error">
                      <a href={csvUrl}
                        style={{ color: '#d32f2f', textDecoration: 'none', fontSize: '12px' }}
                        download={`Rejected_Accounts_Details-${clientCode}-${getPhaseString(phaseNumber)}-${moment(new Date()).format("MM-DD-YYYY")}.csv`}><b>Download Rejected Accounts : {totalRecords}</b>
                      </a>
                    </Button>
                    : null
                  }
                </div>
              </Box>

              {FinalPlacementList?.length ?
                <Box width="25%" p={1}>
                  <Typography variant="h6" gutterBottom className={classes.countMsg}>
                    <b>Total no.of Valid Accounts: </b>
                    <span style={{ color: "green", fontWeight: 900 }}>{FinalPlacementList?.length}</span>
                  </Typography>
                </Box>
                : null}
              {FinalPlacementList?.length ?
                <Box width="21%" p={1} bgcolor="white" className={classes.buttonbox}>
                  <Box p={0} flexShrink={0} bgcolor="white" className={classes.buttonbox}>
                    <Button id="CSVPD_submit_btn" size="small" className={classes.buttons} variant="contained" endIcon={<DoubleArrowIcon />}
                      color="primary" onClick={handleSubmit}> Submit Accounts </Button>
                  </Box>
                  <Box p={0} flexShrink={0} bgcolor="white" className={classes.buttonbox}>
                    <Button id="CSVPD_Back_btn" className={classes.buttons} size="small" variant="contained" startIcon={<DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />}
                      color="primary" onClick={handleBackClick}>Back </Button>
                  </Box>
                </Box>
                : null}
            </Box>
            <Grid item xs={12} style={{ padding: '0px 8px' }}>
              {!filteredPlacementAccountList?.length ?
                <div className={classes.divStyle}>
                  <Container fixed component="div" className={classes.errorContainer}>
                    <Box p={1} flexShrink={0} bgcolor="white" style={{ marginTop: '1.5cm', float: 'right' }}>
                      <VideoPlayer video={video} title={title} />
                    </Box>
                    <Box p={0} flexShrink={0} bgcolor="white" style={{ marginTop: '0.5cm' }} >
                      <img src={errormsg} alt="Error Message" width="200px" height="200px" style={{ marginLeft: '2.5cm' }} />
                    </Box>
                    <Box p={2} flexShrink={0} bgcolor="white" justifyContent="center" justifyItems="center" display="flex">
                      <Typography gutterBottom style={{ fontSize: 20, color: 'black' }}>
                        <span style={{ fontSize: 26, color: 'red' }}><b>There are no accounts to submit</b><br /></span>
                        <span style={{ marginTop: '10px', fontSize: 18, }}>Please go back to the placement page screen to correct mapping errors.
                          If want know more about, workflow of placement page, watch Video in the above Help Icon.</span>
                      </Typography>
                    </Box>
                    <Box p={1} flexShrink={0} bgcolor="white" justifyContent="center" justifyItems="center" display="flex">
                      <Button id="CSVPD_Back_btn" size="small" className={classes.buttons} style={{ borderRadius: 30, fontSize: 12 }} variant="contained" startIcon={<DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />}
                        color="primary" onClick={handleBackClick}>Back to Field Mapping</Button>
                    </Box>
                  </Container>
                </div>
                :
                <TableContainer component={Paper} className={classes.tablebody}>
                  <Table size="medium" stickyHeader>
                    <TableHead className={classes.tableHead} >
                      <TableRow key='table-header'>
                        {templateList?.map((row) => {
                          return (row?.display_name ? (
                            <StyledTableCell className={classes.tableRow1} key={row?.display_name + 'header'} >
                              <span className={classes.textWrap}>
                                {row?.display_name}
                              </span>
                            </StyledTableCell>
                          ) : null)
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredTableRows?.map((r) => {
                        return (
                          <TableRow key={r}>
                            {templateList?.map((row) => {
                              return (row?.display_name ? (
                                <StyledTableCell key={row?.display_name + r} className={classes.tableRow}>
                                  {eval('FinalPlacementList[' + (r - 1) + '].' + row?.field_name)}
                                </StyledTableCell>
                              ) : null)
                            })}
                          </TableRow>)
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              }
            </Grid>
          </Container>
        </Grid>
        <Footer />
      </div >
    </React.Fragment >
  )
}

export default PreviewData