import { IUserPermissions } from './IUserPermissions'

export class ClientUserAccessContext implements IUserPermissions {
    role: UserRole = UserRole.ClientUser
    isGlobalAdmin: boolean = this.role === UserRole.GlobalAdmin
    portalUserExist: boolean = false;
    portalUserLogOn: boolean = false;
    global_admin: number = 0;

    //Permissions
    paymentUpdates: boolean = false;
    closingAccounts: boolean = false;
    rollAccounts: boolean = false;
    placeAccounts: boolean = false;
    editReports: boolean = false;
    createNewUsers: boolean = false;
    uploadUnmappedFile: boolean = false;
    sendEmailtoAdmin: boolean = false;
    sendEmailOnAttachements: boolean = false;
    multiplePlacementAccess: boolean = false;
    multiClientCodePlacementAccess: boolean = false;
    clientConfirmation: boolean = false;
    isFileAccess: boolean = false;
    performanceReport: boolean = false;
    isProspectUser: boolean = false;
    isUserValidated: boolean = false;
    additionalChargesReport: boolean = false;
    trustStatement: boolean = false;
    unmappedCloudFileReport: boolean = false;

    //User Basic Information
    id: number = 0
    objectId: string = ''
    firstName: string = ''
    lastName: string = ''
    userName: string = ''
    phone: string = null
    clientCodes: string[]
    ftpFolder: string = ''
    paymentFileFolder: string = ''
    ackFileFolder: string = ''
    ftpRegex: string = ''
    paymentRegex: string = ''
    reportRegex: string = ''

    constructor() {
    }

    upnEmailAddress: string = '';
}

export enum placementPermission {
    None = 100,
    Enabled = 1,
    Disabled = 0,
}

//User Role
export enum Permission {
    None = 100,
    Enabled = 1,
    Disabled = 0,
}

//User Role
export enum UserRole {
    None = 100,
    SelectRole = 10,
    ClientUser = 0,
    ClientAdmin = 1,
    GlobalAdmin = 2,
    SuperAdmin = 3,
    FileAdmin = 4,
    AgentUser = 5
}

export enum trustStatementAction {
    FileDownloaded = 2,
    FileArchieved = 3,
    FileViewed = 6,
    FileMoved = 6,
    FileUnarchieved = 9
}

export enum trustStatementFileStatus {
    AllFileProcess = 1,
    FileProcessed = 2,
    FileArchieved = 3,
}

export enum trustStatementReportShareStatus {
    UserSignupInvited = 7,
    SignedInProspectUser = 8,
    TrustNotificationAccessProvided = 9,
    TrustNotificationAccessNotProvided = 10,
    TrustNotificationAccessRemoved = 11
}

export enum emailExistStatus {
    UserMenu = 1,
    ProspectUserMenu = 2,
    SharedEmailAddress = 3
}
