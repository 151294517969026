import {
    Box,
    Button, Checkbox, Dialog, DialogContent, FormControl, FormControlLabel, FormGroup, Grid, Select, Snackbar, TextField, Typography
} from '@material-ui/core';
import React, { useState } from 'react';
// Icons
import DoneAllIcon from '@mui/icons-material/DoneAll';
import BorderColorIcon from '@material-ui/icons/BorderColor';
// Models
import { useStyles } from './AgentActivityCSS';
import CustomizeControl from './CustomizeControl';
import { usePost } from '../../../utils/apiHelper';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { AllowOnlyAlphabetsNumbers } from '../../../constants/Constant';
import { IAgentActivities } from '../../../models/AgentTemplate/IAgentActivities';
import { IAgentGlobalActivities } from '../../../models/AgentTemplate/IClientTemplateDetails';

const EditTemplate: React.FC<{ row: IAgentActivities, onSave: () => void, ActivityList: IAgentActivities[] }> = (props) => {
    const classes = useStyles();
    const { row } = props;
    const [isCreate, setIsCreate] = useState(false);
    const [open, setOpen] = useState<boolean>(false);
    const [nameError, setnameError] = useState<string>("");
    const [isCaptured, setIsCaptured] = useState<boolean>(false);
    const [activityProcess, setActivityProcess] = useState<number>(0);
    const [activityParentId, setActivityParentId] = useState<number>(0);
    const [descriptionError, setdescriptionError] = useState<string>("");
    const [isDisableActivityName, setIsDisableActivityName] = useState<boolean>(false);
    const [isDisableActivityDescription, setIsDisableActivityDescription] = useState<boolean>(false);

    const activitiesWithNone = [{ activityMapId: 0, activityName: "None", activityDescription: "None", displayOrder: 0, parentActivityName: null, operationType: 0 }, ...props?.ActivityList];

    let getActivity: IAgentGlobalActivities = {
        activityId: row?.activityMapId,
        activityName: row?.activityName,
        activityDescription: row?.activityDescription,
        displayOrder: row?.displayOrder,
        operationType: row?.operationType,
        parentActivityName: row?.parentActivityName,
        activityCountReport: row?.activityCountReport,
        agentReport: row?.agentReport,
        caseCreatedReport: row?.caseCreatedReport,
        includeInCount: row?.includeInCount
    }
    const [localState, setLocalState] = useState(getActivity);

    const handleClose = () => {
        setOpen(false);
        setIsCaptured(false);
        setLocalState(getActivity);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const UpdateMessage = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsCreate(false);
    };

    const handleEditGroupNameChange = (event) => {
        let name = event.target.name;
        activitiesWithNone?.forEach((a) => {
            if (event.target.value === a?.activityName) {
                setActivityParentId(a?.activityMapId);
            }
        })
        setLocalState({
            ...localState,
            [name]: event.target.value
        });
    }

    const handleUpdate = (e) => {
        let name = e.target.name;
        setLocalState({
            ...localState,
            [name]: e.target.value
        });
    }

    const validateActivityName = (e) => {
        const activityName = e.target.value;
        setIsCaptured(true);
        if (!AllowOnlyAlphabetsNumbers.test(activityName)) {
            (async () => {
                let checkActivityName = { "activityName": activityName };
                await usePost<any>("AgentUser/CheckActivityExists", checkActivityName).then(async (r) => {
                    if (r?.data[0]?.isActivityNameCheck) {
                        setActivityProcess(2);
                        setnameError("Activity already exists, Please try different Activity!");
                        setIsDisableActivityName(true);
                    } else {
                        setnameError("");
                        setActivityProcess(3);
                        setIsDisableActivityName(false);
                    }
                }).finally(() => {
                });
            })()
        } else if (!e.target.value?.trim()?.length) {
            setActivityProcess(0);
            setnameError("Activity Name is Required");
            setIsDisableActivityName(true);
        } else {
            setActivityProcess(1);
            setnameError("Please enter characters only");
            setIsDisableActivityName(true);
        }
    };

    const HandleTempDescriptionVal = (e) => {
        const ActivityDescription = e.target.value;
        if (!ActivityDescription.match(AllowOnlyAlphabetsNumbers)) {
            setdescriptionError("");
            setIsDisableActivityDescription(false);
        } else if (ActivityDescription === "") {
            setdescriptionError("Activity Description is Required");
            setIsDisableActivityDescription(true);
        } else {
            setdescriptionError("Please enter characters only");
            setIsDisableActivityDescription(true);
        }
    };

    const updateAgentActivity = () => {
        let activityParentSelectedId = 0;
        (async () => {
            activitiesWithNone?.forEach((a) => {
                if (row?.parentActivityName === a?.activityName) {
                    activityParentSelectedId = a?.activityMapId;
                }
            })
            let request = {
                "activityId": localState?.activityId,
                "activityName": localState?.activityName,
                "activityDescription": localState?.activityDescription,
                "activityParentId": activityParentId === 0 ? activityParentSelectedId : activityParentId,
                "displayOrder": localState?.displayOrder,
                "OperationType": localState?.operationType,
                "agentReport": localState?.agentReport,
                "caseCreatedReport": localState?.caseCreatedReport,
                "activityCountReport": localState?.activityCountReport,
                "includeInCount": localState?.includeInCount
            }
            await usePost("AgentUser/ModifyAgentActivity", request);
            props?.onSave();
            setIsCreate(true);
            setOpen(false);
        })()
    }

    function resetValues() {
        setnameError("");
        setActivityProcess(0);
        setdescriptionError("");
        setIsDisableActivityName(false);
        setIsDisableActivityDescription(false);
    }

    const handleChecked = (event) => {
        const name = event.target.name;
        setLocalState({
            ...localState,
            [name]: event.target.checked,
        });
    };

    return (
        <React.Fragment>
            <Button id="EditTemplate_btn" size="small" startIcon={<BorderColorIcon />} variant="contained" color="primary" onClick={() => { setOpen(true); }} className={classes.btnManTemp1}>
                Edit
            </Button>

            <Dialog open={open} PaperProps={{ style: { borderRadius: 15 } }} classes={{ paper: classes.customizeDialogePaper }} TransitionComponent={Transition}
                onClose={handleClose} aria-labelledby="responsive-dialog-title" >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        EDIT ACTIVITY
                    </Typography>
                </DialogTitleHeader>
                <DialogContent >
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={6} >
                            <Typography variant='subtitle1' className={classes.activityName}>
                                <b>Activity Name</b>
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} >
                            <Typography variant='subtitle1' className={classes.activityName}>
                                <b>Activity Description</b>
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} >
                            <TextField
                                size="small"
                                id="CNT_Name"
                                variant="outlined"
                                name="activityName"
                                autoComplete="off"
                                error={!!nameError}
                                helperText={nameError}
                                fullWidth margin="dense"
                                label="Edit Activity Name"
                                style={{ width: "97%" }}
                                value={localState?.activityName}
                                onChange={(e) => { handleUpdate(e); validateActivityName(e); }}
                                InputProps={{
                                    classes: {
                                        root: `${activityProcess === 0 ? classes.blueBorderBottom : activityProcess === 1 || activityProcess === 2 ? classes.redBorderBottom : classes.greenBorderBottom}`,
                                        notchedOutline: `${activityProcess === 0 ? classes.blueBorderColor : activityProcess === 1 || activityProcess === 2 ? classes.redBorderColor : classes.greenBorderColor}`,
                                    },
                                }}
                                className={`${isCaptured && activityProcess === 0 ? classes.blueBorderBottom : activityProcess === 1 || activityProcess === 2 ? classes.textFieldBorderRed : classes.textFieldBorderGreen}`}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} >
                            <TextField
                                size="small"
                                id="CNT_Name"
                                variant="outlined"
                                autoComplete="off"
                                fullWidth margin="dense"
                                name="activityDescription"
                                error={!!descriptionError}
                                label="Edit Description"
                                helperText={descriptionError}
                                value={localState?.activityDescription}
                                onChange={(e) => { handleUpdate(e); HandleTempDescriptionVal(e); }}
                                className={`${!!descriptionError ? classes.textFieldBorderRed : classes.blueBorderBottom}`}
                                InputProps={{
                                    classes: {
                                        root: `${!!descriptionError ? classes.redBorderBottom : classes.blueBorderBottom}`,
                                        notchedOutline: `${!!descriptionError ? classes.redBorderColor : classes.blueBorderColor}`,
                                    },
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} >
                            <Typography variant='subtitle1' className={classes.activityName}>
                                <b>Select Parent Activity</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} >
                            <FormControl size="small" variant="outlined" className={classes.activityDropDownStyle}>
                                <Select native
                                    value={localState?.parentActivityName}
                                    onChange={(e) => { handleEditGroupNameChange(e); }}
                                    name="parentActivityName" variant="outlined"
                                    inputProps={{
                                        name: 'parentActivityName',
                                        id: 'outlined-age-native-simple',
                                    }}>
                                    {activitiesWithNone?.map((r) => {
                                        return (
                                            <option value={r?.activityName}>{r?.activityName}</option>
                                        )
                                    })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={7} >
                                <Box className={classes.userboxviewStyle}>
                                    <Typography variant="h6" className={classes.headerStyle}>
                                        {"Include in"}
                                    </Typography>
                                    <FormControl component="fieldset" className={classes.formControlStyle}>
                                        <FormGroup row>
                                            <FormControlLabel
                                                id="agentReport"
                                                className={classes.permission}
                                                control={<Checkbox size="medium" color="primary" name="agentReport" checked={localState?.agentReport} onClick={handleChecked} />}
                                                label={<Typography className={classes.activityName}>{"Agent Report"}</Typography>}
                                            />
                                            <FormControlLabel
                                                id="caseCreatedReport"
                                                className={classes.permission}
                                                control={<Checkbox size="medium" color="primary" name="caseCreatedReport" checked={localState?.caseCreatedReport} onClick={handleChecked} />}
                                                label={<Typography className={classes.activityName}>{"Case Created Report"}</Typography>}
                                            />
                                            <FormControlLabel
                                                id="activityCountReport"
                                                className={classes.permission}
                                                control={<Checkbox size="medium" color="primary" name="activityCountReport" checked={localState?.activityCountReport} onClick={handleChecked} />}
                                                label={<Typography className={classes.activityName}>{"Count Report"}</Typography>}
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item xs={6} sm={5}>
                                <Box className={classes.userboxviewStyle}>
                                    <Typography variant="h6" className={classes.headerStyle}>
                                        {"Include in"}
                                    </Typography>
                                    <FormControl component="fieldset" className={classes.formControlStyle}>
                                        <FormGroup row>
                                            <FormControlLabel
                                                id="includeInCount"
                                                className={classes.permission}
                                                control={<Checkbox size="medium" color="primary" name="includeInCount" checked={localState?.includeInCount} onClick={handleChecked} />}
                                                label={<Typography className={classes.activityName}>{"Activity Count"}</Typography>}
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} >
                            <div className={classes.editDisplay}>
                                <Button id="CNT_CreateTemplate_Btn" size="small" className={classes.edittemplate}
                                    startIcon={<DoneAllIcon />} variant="contained" autoFocus color="primary" onClick={() => { updateAgentActivity(); resetValues(); }}
                                    disabled={(!localState?.activityName || !localState.activityDescription) || isDisableActivityName || isDisableActivityDescription}>
                                    Update Activity
                                </Button>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <CustomizeControl activityId={props?.row?.activityMapId} actionType={1} />
                        </Grid>

                    </Grid>
                </DialogContent>
            </Dialog>

            <Snackbar className="snackBarStyle" open={isCreate} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={UpdateMessage}>
                <Alert onClose={UpdateMessage} severity="success" className="alertStyle">
                    Activity Updated Successfully.
                </Alert>
            </Snackbar>
        </React.Fragment >
    );
}

export default EditTemplate