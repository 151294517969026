import { Box, Grid, IconButton, makeStyles, TableContainer, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import { useGlobalState } from "../store/GlobalStore";
// Icon Image
import AckReportView from "../components/AckReport/AckReportView";
import manageReport from "../images/agentUser/report.png";
import performanceReport from "../images/globalsetting/Icon11.svg";
import webviewReport from "../images/Report/webviewreport.svg";
import powerBi from "../images/Report/powerbi.svg";
import addhoc from "../images/Report/ad-hoc.svg";
import requestedRepot from "../images/Report/requestedReport.svg";
import trustStatementReport from "../images/trustStatement/trust-statement-report.svg";
import AckAdditionalReportView from "../components/AddinalChargesAckReport/AckAdditionalReportView";
import { usePost } from "../utils/apiHelper";
import { IRequestedReportDetails } from "../models/Reports/IReportStatusList";
import UnmappedReport from "../components/Report/UnmappedFileReport/UnmappedReport";

const useStyles = makeStyles((theme) => ({
    text: {
        color: "black",
        textAlign: "center",
        fontSize: "16px",
        fontWeight: "bold",
    },
    card1: {
        display: "block",
        width: "5.5cm",
        height: "4cm",
        marginLeft: "30px",
        marginTop: "0.5cm",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        textDecoration: "none",
        "&:hover": {
            color: "black",
            backgroundColor: "white",
            borderRadius: "10px",
            transform: "scale(1.1)",
            border: "2px blue solid",
            cursor: "pointer",
            boxShadow: "0 10px 10px rgba(0, 0, 0, 0.4)",
        },
    },
    div: {
        flexGrow: 1,
    },
    adminLayout: {
        maxHeight: 800,
        minHeight: 800,
        [theme.breakpoints.up("lg")]: {
            maxHeight: "calc( 100vh - 170px)",
            minHeight: "calc( 100vh - 170px)",
        },
    },
    image: {
        width: "70px",
        height: "70px",
        textAlign: "center",
    },
    image1: {
        width: "90px",
        height: "70px",
        textAlign: "center",
    },
    IconButtonripple: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    Tooltip: {
        fontSize: "14px",
    },
    textStyle: {
        marginTop: "5px",
        marginLeft: "15px",
        float: "left",
    },
    TextNormal: {
        marginTop: "-180px",
    },
    footerStyle: {
        marginTop: "30px",
    },
    footerStyle1: {
        marginTop: "200px",
        float: "left",
        marginLeft: "20px",
    },
}));

const Reports = () => {
    const classes = useStyles();
    const { state } = useGlobalState();
    const [reportDetails, setReportDetails] = useState<IRequestedReportDetails[]>();
    const [totalRecordCount, setTotalRecordCount] = useState<number>(0);

    useEffect(() => {
        const GetReportDetail = async () => {
            let request = {
                UserId: state?.userAccessContext?.id,
                RowsPerPage: 50,
                SearchParameters: null,
                StartingRecordNumber: 1,
                userRole: state.userAccessContext?.role,
            };
            await usePost<{
                requestedReportContract: IRequestedReportDetails[];
                totalRecordCount: number;
            }>("GetUserRequestedReport", request)
                .then((r) => {
                    setReportDetails(r?.data?.requestedReportContract);
                    setTotalRecordCount(r?.data?.totalRecordCount);
                })
                .finally(() => { });
        };
        GetReportDetail();
    }, []);

    return (
        <React.Fragment>
            <div className={classes.div}>
                <Typography variant="h5" gutterBottom className={`${classes.textStyle} ${"headertitle"}`}                >
                    <b>LIST OF REPORTS</b>
                </Typography>
                <TableContainer
                    className={` ${classes.adminLayout} ${"scrollbox"} ${"on-scrollbar"}`}
                >
                    <Grid container spacing={0}>
                        <Grid item xs={6} sm={6} md={4} lg={2}>
                            <Link to="/webviewreport" className={classes.card1}>
                                <Box>
                                    <IconButton
                                        id="Webview_report"
                                        aria-label="global_message"
                                        component={Link}
                                        to="/webviewreport"
                                        className={classes.IconButtonripple}
                                    >
                                        <img
                                            src={webviewReport}
                                            alt="Webview_Report"
                                            className={classes.image}
                                        />
                                    </IconButton>
                                    <Box>
                                        <Typography variant="h6" className={classes.text}>
                                            Webview Reports
                                        </Typography>
                                    </Box>
                                </Box>
                            </Link>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4} lg={2}>
                            <Box className={classes.card1}>
                                <AckReportView />
                            </Box>
                        </Grid>
                        {state?.userAccessContext?.additionalChargesReport ? (
                            <Grid item xs={6} sm={6} md={4} lg={2}>
                                <Box className={classes.card1}>
                                    <AckAdditionalReportView />
                                </Box>
                            </Grid>
                        ) : null}
                        <Grid item xs={6} sm={6} md={4} lg={2}>
                            <Link to="/performancereportview" className={classes.card1}>
                                <Box>
                                    <IconButton
                                        id="performanceReport"
                                        aria-label="performanceReport"
                                        component={Link}
                                        to="/performancereportview"
                                        className={classes.IconButtonripple}
                                    >
                                        <img
                                            src={performanceReport}
                                            alt="performance_Report"
                                            className={classes.image}
                                        />
                                    </IconButton>
                                    <Box>
                                        <Typography variant="h6" className={classes.text}>
                                            Performance Reports
                                        </Typography>
                                    </Box>
                                </Box>
                            </Link>
                        </Grid>
                        {state.userAccessContext?.role === 3 ? (
                            <Grid item xs={6} sm={6} md={4} lg={2}>
                                <Link to="/managereports" className={classes.card1}>
                                    <Box>
                                        <IconButton
                                            aria-label="manage_reports"
                                            component={Link}
                                            to="/managereports"
                                            className={classes.IconButtonripple}
                                        >
                                            <img
                                                src={manageReport}
                                                alt="manage_reports"
                                                className={classes.image}
                                            />
                                        </IconButton>
                                        <Box>
                                            <Typography variant="h6" className={classes.text}>
                                                Manage Performance Reports
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Link>
                            </Grid>
                        ) : null}
                        <Grid item xs={6} sm={6} md={4} lg={2}>
                            <Link to="" className={classes.card1}>
                                <Box>
                                    <IconButton
                                        id="performanceReport"
                                        aria-label="performanceReport"
                                        component={Link}
                                        to=""
                                        className={classes.IconButtonripple}
                                    >
                                        <img
                                            src={powerBi}
                                            alt="performance_Report"
                                            className={classes.image}
                                        />
                                    </IconButton>
                                    <Box>
                                        <Typography variant="h6" className={classes.text}>
                                            Power BI Reports
                                        </Typography>
                                    </Box>
                                </Box>
                            </Link>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4} lg={2}>
                            <Link to="" className={classes.card1}>
                                <Box>
                                    <IconButton
                                        id="performanceReport"
                                        aria-label="performanceReport"
                                        component={Link}
                                        to=""
                                        className={classes.IconButtonripple}
                                    >
                                        <img
                                            src={addhoc}
                                            alt="performance_Report"
                                            className={classes.image1}
                                        />
                                    </IconButton>
                                    <Box>
                                        <Typography variant="h6" className={classes.text}>
                                            AD-HOC Reports
                                        </Typography>
                                    </Box>
                                </Box>
                            </Link>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4} lg={2}>
                            <Link to="/requestedReport" className={classes.card1}>
                                <Box>
                                    <IconButton
                                        id="requestedReport"
                                        aria-label="requestedReport"
                                        component={Link}
                                        to="/requestedReport"
                                        className={classes.IconButtonripple}
                                    >
                                        <img
                                            src={requestedRepot}
                                            alt="reportsRequerequestedReportsted"
                                            className={classes.image}
                                        />
                                    </IconButton>
                                    <Box>
                                        <Typography variant="h6" className={classes.text}>
                                            Reports Requested
                                        </Typography>
                                    </Box>
                                </Box>
                            </Link>
                        </Grid>
                        {state.userAccessContext?.role === 3 ? (
                            <Grid item xs={6} sm={6} md={4} lg={2}>
                                <Link to="/trustStatementReport" className={classes.card1}>
                                    <Box>
                                        <IconButton
                                            aria-label="trust-statement-report"
                                            component={Link}
                                            to="/trustStatementReport"
                                            className={classes.IconButtonripple}
                                        >
                                            <img
                                                src={trustStatementReport}
                                                alt="trust-statement-report"
                                                className={classes.image}
                                            />
                                        </IconButton>
                                        <Box>
                                            <Typography variant="h6" className={classes.text}>
                                                Share Trust Statement
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Link>
                            </Grid>
                        ) : null}
                        {state?.userAccessContext?.unmappedCloudFileReport &&
                            (state.userAccessContext?.role === 2 || state.userAccessContext?.role === 3) ? (
                            <Grid item xs={6} sm={6} md={4} lg={2}>
                                <Box className={classes.card1}>
                                    <UnmappedReport />
                                </Box>
                            </Grid>
                        ) : null}
                    </Grid>
                </TableContainer>
                <Footer />
            </div>
        </React.Fragment>
    );
};

export default Reports;
