import Autocomplete, { AutocompleteCloseReason, AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
    Box, Button, Checkbox, Dialog, DialogContent, FormControl, FormControlLabel, FormGroup,
    Grid, IconButton, Paper, Typography, styled, useMediaQuery, useTheme
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
// Icons
import SearchIcon from "@material-ui/icons/Search";
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// Componant
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import useStyles from './AgentUserCss';
// Models
import { Card, Chip, CircularProgress, ClickAwayListener, DialogActions, FormLabel, InputAdornment, TextField, Tooltip } from '@material-ui/core';
import _ from 'lodash';
import { CONT, FIRSTSTP, PREC } from '../../constants/Constant';
import { IAgentClient, IAgentClientCode, IFTEHistoryDetail, IPhaseSelection } from '../../models/AgentUser/IAgentClientDetails';
import { IAgentUserDetails } from '../../models/AgentUser/IAgentUserDetails';
import { IClientPhaseDetails } from '../../models/AgentUser/IClientPhaseDetails';
import { useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { IGetUsers } from '../../models/UploadLog/GetUsersList';

interface Option {
    label: string;
}

const NotificationPermission = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const GetAgentClientList = "AgentUser/GetAgentClientList";
    const CreateAgentUser = "AgentUser/CreateAgentUser";
    const AgentFteInsert = "AgentUser/InsertFTEHistory";

    const theme = useTheme();
    const { state } = useGlobalState();

    const [phase, setPhase] = useState<IPhaseSelection[]>([]);
    const [agentId, setAgentId] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isCaptured, setIsCaptured] = useState(false);
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [ClientCodeOpen, setClientCodeOpen] = useState(false);
    const [deselectClient, setDeselectClient] = useState(false);
    const [accessActivity, setAccessActivity] = useState(true);

    const [Emailerror, setEmailError] = useState("");
    const [Lastnameerror, setLastNameError] = useState("");
    const [firstnameerror, setFirstNameError] = useState("");
    const [searchClientCode, setSearchClientCode] = useState("");
    const fullSreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [confirmedClients, setConfirmedClients] = useState<IAgentClient[]>([]);
    const [selectedClients, setSelectedClients] = useState<any>([]);
    const [fteHistoryDetail, setFteHistoryDetails] = useState<IFTEHistoryDetail[]>([]);

    const [selectedValue, setSelectedValue] = useState<string | null>(null);
    const [options] = useState<string[]>(['Option 1', 'Option 2', 'Option 3']);

    const [SelectActiveUser, setSelectActiveUser] = useState<any>([]);
    const [userList, setuserList] = useState<IGetUsers[]>([]);
    const [selectedUser, setSelectedUser] = useState(false);
    const usersLoading = userList.length === 0;


    let userDetails: IAgentUserDetails = {
        emailAddress: "",
        firstName: "",
        lastName: "",
        createdDate: moment(new Date())?.format("YYYY-MM-DD"),
        modifiedDate: moment(new Date())?.format("YYYY-MM-DD"),
        paymentUpdates: false,
        closeAccounts: false,
        rollAccounts: false,
        clientInformation: false,
        readOnlyAccess: false,
        isActive: true,
        portalUserExist: false,
        remainingAccountCount: false,
        dailyActivityCount: false,
        dailyTargetedCount: false,
        accountPlacementCount: false,
        accountUpdateHistory: false,
        accountActivityHistory: false,
        allFirstPartySelected: false,
        allPrecSelected: false,
        allContSelected: false,
        agentUsersReport: false,
        ftePermission: false,
        disputeValidation: false,
        paymentVerification: false,
        backupDocument: false,
        caseCreatedReport: false,
        activityCountReport: false,
    }

    let clientPhaseDetails: IClientPhaseDetails = {
        isFirstParty: true,
        isFlatFee: false,
        isContingency: false
    }

    const [localState, setLocalState] = useState(userDetails);
    const [selectedPhase, setSelectedPhase] = useState(clientPhaseDetails);

    const handleClose = () => {
        setAccessActivity(false);
        setOpen(false);
        setShowProgressBar(false);
        setSelectedClients([]);
        setLocalState(userDetails);
        setIsSubmit(false);
        setIsCaptured(false);
        resetValues();
    };

    const resetValues = () => {
        setFirstNameError(null);
        setLastNameError(null);
        setEmailError(null);
    }

    const handleClickAway = (e, isSearchClear: boolean) => {
        setClientCodeOpen(false);
        if (isSearchClear) {
            handleClear();
        }
    };

    const handleClientSelect = (event, selected) => {
        setSelectedClients(selected);
        if (selected?.length <= 0) {
            setCheckAll(false);
        }
    }

    const handleSearchClient = (e) => {
        setSearchClientCode(e.target.value);
    }

    const checkAllChange = (event) => {
        setCheckAll(event.target.checked);
        if (event.target.checked) {
            var NewClientList = confirmedClients.filter((c) => {
                return !selectedClients.find((r) => {
                    return c.clientCode == r.clientCode
                })
            });
            setSelectedClients([...new Set(selectedClients?.concat(NewClientList?.map((r) => r)))]);
            if (selectedPhase?.isFirstParty && selectedPhase?.isFlatFee && selectedPhase?.isContingency) {
                setLocalState({ ...localState, allFirstPartySelected: true, allPrecSelected: true, allContSelected: true });
            } else if (selectedPhase?.isFirstParty && selectedPhase?.isFlatFee) {
                setLocalState({ ...localState, allFirstPartySelected: true, allPrecSelected: true });
            } else if (selectedPhase?.isFirstParty && selectedPhase?.isContingency) {
                setLocalState({ ...localState, allFirstPartySelected: true, allContSelected: true });
            } else if (selectedPhase?.isFlatFee && selectedPhase?.isContingency) {
                setLocalState({ ...localState, allPrecSelected: true, allContSelected: true });
            } else if (selectedPhase?.isFirstParty) {
                setLocalState({ ...localState, allFirstPartySelected: true });
            } else if (selectedPhase?.isFlatFee) {
                setLocalState({ ...localState, allPrecSelected: true });
            } else if (selectedPhase?.isContingency) {
                setLocalState({ ...localState, allContSelected: true });
            }
            setClientCodeOpen(false);
        }
        setCheckAll(false);
        handleClear();
        setSearchClientCode("");
    };

    const DeselectAllChange = (event) => {
        setDeselectClient(event.target.checked);
        if (event.target.checked) {
            setSelectedClients([]);
            setClientCodeOpen(false);
        }
        setLocalState({ ...localState, allFirstPartySelected: false, allPrecSelected: false, allContSelected: false });
        setDeselectClient(false);
        handleClear();
        setSearchClientCode("");
    };

    useEffect(() => {
        (async () => {
            insertAgentFTEHistory();
        })();
    }, [fteHistoryDetail])

    const insertAgentFTEHistory = () => {
        let request = {
            "agentFteHistory": fteHistoryDetail,
            "fteAction": localState?.ftePermission ? 1 : 2,
            "fteActionTakenBy": state?.userAccessContext?.id
        }
        usePost<any>(AgentFteInsert, request).then((r) => {

        }).finally(() => {
            setShowProgressBar(false);
        });
    }

    const handleBatchSave = ((userInfo: IAgentUserDetails[], batchClientCodeList: IAgentClientCode[]) => {
        let newHistoryList = [];
        let request = {
            "createdBy": state?.userAccessContext?.id,
            "agentUserDetails": userInfo,
            "agentClientDetails": batchClientCodeList
        }
        usePost<any>(CreateAgentUser, request).then((r) => {
            setAgentId(r?.data[0]?.agentUserId);
            let newResult: IFTEHistoryDetail = {
                agentUserId: r?.data[0]?.agentUserId,
                clientCode: null,
                phaseCode: null,
                previousActivityTemplateId: null,
                newActivityTemplateId: null
            }
            newHistoryList.push(newResult);
            setFteHistoryDetails(newHistoryList);
        });
    });

    useEffect(() => {
        if (!selectedPhase?.isFirstParty && !selectedPhase?.isFlatFee && !selectedPhase?.isContingency) {
            setSelectedPhase({
                ...selectedPhase,
                isFirstParty: true
            });
            let PhaseList = _.cloneDeep(phase);
            let phaseCode: IPhaseSelection = {
                phase: FIRSTSTP
            }
            PhaseList.push(phaseCode);
            setPhase(PhaseList);
            handleClientGet(PhaseList);
        }
    }, [selectedPhase])

    const handleClientGet = (Phase: IPhaseSelection[]) => {
        (async () => {
            setLoading(true);
            let request = {
                "searchText": searchClientCode?.trim().toLocaleUpperCase(),
                "phaseDetails": Phase?.map((r) => r?.phase).join(",")
            }
            await usePost<IAgentClient[]>(GetAgentClientList, request).then((r) => {
                setConfirmedClients(r?.data);
                setLoading(false);
            }).finally(() => {
                setCheckAll(false);
            });
        })()
    };

    const handlePhaseChange = (e) => {
        setSelectedPhase({
            ...selectedPhase,
            [e.target.name]: e.target.checked
        });

        let PhaseList = _.cloneDeep(phase);
        if (phase.findIndex(x => x.phase == e.target.value) <= -1) {
            let phaseCode = { phase: e?.target.value };
            if (!PhaseList?.includes(phaseCode)) {
                PhaseList.push(phaseCode);
            }
            setPhase(PhaseList);
        }
        else {
            if (phase.findIndex(x => x.phase == e.target.value) > -1) {
                PhaseList = phase.filter(x => x.phase !== e.target.value)
                setPhase(phase.filter(x => x.phase !== e.target.value));
            };
        }
        handleClientOpen(PhaseList);
    }

    useEffect(() => {
        (async () => {
            setLoading(true);
            let request = {
                "searchText": searchClientCode?.trim().toLocaleUpperCase(),
                "phaseDetails": FIRSTSTP
            }
            await usePost<IAgentClient[]>(GetAgentClientList, request).then((r) => {
                setConfirmedClients(r?.data);
                setLoading(false);
                let PhaseList = _.cloneDeep(phase);
                let phaseCode = { phase: FIRSTSTP };
                PhaseList.push(phaseCode);
                setPhase(PhaseList);
            });
        })()
    }, [])

    const handleClientOpen = (Phase: IPhaseSelection[]) => {
        (async () => {
            setConfirmedClients([]);
            setLoading(true);
            setOpen(true);
            let request = {
                "searchText": searchClientCode?.trim().toLocaleUpperCase(),
                "phaseDetails": Phase?.map((r) => r?.phase).join(",")
            }
            await usePost<IAgentClient[]>(GetAgentClientList, request).then((r) => {
                setConfirmedClients(r?.data);
                setLoading(false);
            }).finally(() => {
                setCheckAll(false);
            });
        })()
    };

    const handleClear = () => {
        (async () => {
            setOpen(true);
            let request = {
                "searchText": null,
                "phaseDetails": phase?.map((r) => r?.phase).join(",")
            }
            await usePost<IAgentClient[]>(GetAgentClientList, request).then((r) => {
                setConfirmedClients(r?.data);
            }).finally(() => {
                setSearchClientCode("");
            });
        })()
    };

    const onDelete = (clientcode: string) => () => {
        setSelectedClients((value) => value.filter((v) => (v.clientCode !== clientcode)));
    };

    const onUserDelete = (userName: string) => () => {
        setSelectActiveUser((value) => value.filter((v) => (v.clientCode !== userName)));
    };


    const handleOpen = () => {
        setOpen(true);
    }

    const userDetailsList = [
        { userId: 1, userName: 'Kiran Priya' },
        { userId: 2, userName: 'Jayasri Rani' },
        { userId: 3, userName: 'Kelly Burford' },
        { userId: 4, userName: 'Zachary	Brown' },
        { userId: 5, userName: 'Zach Jones' },
        { userId: 6, userName: 'Zach Larson' },
        { userId: 7, userName: 'Yuleni Aguilar' },
        { userId: 8, userName: 'Yoav Yogev' },
        { userId: 9, userName: 'Yazmin Farinas' },
        // { userId: 10, userName: 'Test user10' },
        // { userId: 10, userName: 'Test user10' },
        // { userId: 10, userName: 'Test user10' },
        // { userId: 10, userName: 'Test user10' },
        // { userId: 10, userName: 'Test user10' },
        // { userId: 10, userName: 'Test user10' },
        // { userId: 10, userName: 'Test user10' },
        // { userId: 10, userName: 'Test user10' },
        // { userId: 10, userName: 'Test user10' },
    ]

    const clientCode = [
        { userId: 1, userName: 'Client Code1' },
        { userId: 2, userName: 'Client Code2' },
        { userId: 3, userName: 'Client Code3' },
        { userId: 4, userName: 'Client Code4' },
        { userId: 5, userName: 'Client Code5' },
        { userId: 6, userName: 'Client Code6' },
        { userId: 7, userName: 'Client Code7' },
        { userId: 8, userName: 'Client Code8' },
        { userId: 9, userName: 'Client Code9' },
        { userId: 10, userName: 'Client Code10' },
    ]

    const top100Films = [
        { title: 'The Shawshank Redemption', year: 1994 },
        { title: 'The Godfather', year: 1972 },
        { title: 'The Godfather: Part II', year: 1974 },
        { title: 'The Dark Knight', year: 2008 },
        { title: '12 Angry Men', year: 1957 },
        { title: "Schindler's List", year: 1993 },
    ]

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const handleSelect = (value: string) => {
        setSelectedValue(value);
    };

    interface IUserDetails {
        userName: string;
    }

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;


    const [selectedUsers, setSelectedUsers] = useState<IUserDetails[]>([]);
    const userDetailsListDetails: IUserDetails[] = [
        { userName: 'User 1' },
        { userName: 'User 2' },
        { userName: 'User 3' },
        // Add more users as needed
    ];

    const handleAutocompleteChange = (_, value: IUserDetails[]) => {
        setSelectedUsers(value);
    };

    useEffect(() => {
        (async () => {
            try {
                if (state?.userAccessContext?.role === 2 || state?.userAccessContext?.role === 3) {
                    await useFetch<IGetUsers[]>("UploadLog/UserGet").then((GetUsersList) => {
                        setuserList(GetUsersList?.data);
                    });
                }
            }
            catch (ex) {

            }
            finally {

            }
        })()
    }, [])

    function onUsersSelect(event, value) {
        setSelectActiveUser(value);
        setSelectedUser(true);
        (async () => {
            try {
                if (state?.userAccessContext?.role === 2 || state?.userAccessContext?.role === 3) {
                    await useFetch<IGetUsers[]>("UploadLog/UserGet?id=" + value.id).then((GetUsers) => {
                        setuserList(GetUsers?.data);
                    });
                }
                else if (state.userAccessContext.role === 1 || state.userAccessContext.role === 0) {
                    await useFetch<IGetUsers[]>("UploadLog/Getuserforclientuploadlog?userid=" + value.user_id).then((GetUserForClient) => {
                        setuserList(GetUserForClient?.data);
                    });
                }
            }
            catch (ex) {

            }
            finally {
            }
        })()
    }


    return (
        <div>
            <Tooltip title="Manage Notification">
                <IconButton size='small' onClick={handleOpen}>
                    <NotificationsIcon />
                </IconButton>
            </Tooltip>

            <Dialog
                PaperProps={{ style: { borderRadius: 15 } }}
                maxWidth={'lg'}
                TransitionComponent={Transition}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" className={classes.titleHeaderStyle}>
                        MANAGE NOTIFICATION ACCESS
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Grid container spacing={1} mt={1}>
                        <Grid item xs={12}>
                            <Box m={0} boxShadow={2} p={1} pl={2} pr={2} mt={1} borderRadius={1}>
                                <Typography variant='body2' bgcolor={'rgb(97 175 254 / 20%)'} color={'rgb(1, 41, 114)'} p={1} borderRadius={1} pl={2} mb={1}>
                                    <b>Notification  Type</b>
                                </Typography>
                                <FormControl component="fieldset">
                                    <FormGroup row>
                                        <FormControlLabel
                                            id="U_CU_paymentUpdates"
                                            className={classes.permission}
                                            control={<Checkbox size="small" name="disputeNotification" />}
                                            label={<span className={classes.inputFontSize}>{"Dispute Validation Notification"}</span>}
                                        />
                                        <FormControlLabel
                                            id="U_CU_closeAccounts"
                                            className={classes.permission}
                                            control={<Checkbox size="small" name="paymentNotification" />}
                                            label={<span className={classes.inputFontSize}>{"Payment Verification Notification"}</span>}
                                        />
                                        <FormControlLabel
                                            id="U_CU_rollAccounts"
                                            className={classes.permission}
                                            control={<Checkbox size="small" name="backupNotification" />}
                                            label={<span className={classes.inputFontSize}>{"Backup Document Request Notification"}</span>}
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box m={0} boxShadow={2} p={1} pl={2} pr={2} mt={1} borderRadius={1}>
                                <Typography variant='body2' bgcolor={'rgb(97 175 254 / 20%)'} color={'rgb(1, 41, 114)'} p={1} borderRadius={1} pl={2} mb={1}>
                                    <b>Assign to User</b> {/* {selectedClients?.length > 0 ? <span><b>: ({" Selected Users"} : {selectedUser.length} ) </b></span> : null} */}
                                </Typography>

                                <Card className={classes.boxBorder}>
                                    <Scrollbars autoHide
                                        autoHideTimeout={1000}
                                        autoHideDuration={10}
                                        autoHeight
                                        className={`${classes.textBoxStyle2} ${classes.scrollMargin}`}
                                        autoHeightMin={115}
                                        autoHeightMax={115}
                                        style={{ width: '100%' }}>
                                        <div className={`${classes.valueContainer}`} >
                                            {
                                                userDetailsList.map((v, index) => (
                                                    <Chip key={index} size="small" color="secondary" label={v.userName} onDelete={onDelete(v.userName)} />
                                                ))
                                            }
                                            {/* {selectedClients?.length > 0 ?
                                                (
                                                    <>
                                                        {
                                                            selectedUser.map((v) => (
                                                                <Chip key={v} size="small" color="secondary" label={v.} onDelete={onDelete(v.clientCode)} />
                                                            ))
                                                        }
                                                    </>
                                                )
                                                :
                                                <Typography variant='body2' color={'blue'} mt={2} align='center'>
                                                    <InfoIcon fontSize='large' />
                                                    <br />
                                                    <b>Selected user list</b>
                                                </Typography>
                                            } */}
                                        </div>
                                    </Scrollbars>
                                </Card>


                                <Autocomplete size="small"
                                    id="UpLog_SelectUsers_DropDown"
                                    autoComplete autoHighlight
                                    classes={{
                                        paper: classes.paper1,
                                        option: classes.smallFont
                                    }}
                                    // className={classes.textField1}
                                    options={userList}
                                    getOptionLabel={(option) => option.fullname} clearOnEscape={true}
                                    value={SelectActiveUser}
                                    onChange={onUsersSelect}
                                    loading={usersLoading}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            <span style={option.isActive === true ? { color: "green" } : { color: "#FA8072" }}> {option.fullname}</span>
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Users" margin="normal" variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {usersLoading ? <CircularProgress style={{ height: "25px", width: "25px" }} color="inherit" /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />

                                {/* <Autocomplete
                                    multiple
                                    id="checkboxes-tags-demo"
                                    options={userDetailsListDetails}
                                    fullWidth
                                    size='small'
                                    disableCloseOnSelect
                                    style={{ marginTop: 1 }}
                                    getOptionLabel={(option) => option.userName}
                                    onChange={handleAutocompleteChange}
                                    renderOption={(props, option: IUserDetails, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                size='small'
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.userName}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label="Search User" placeholder="Search User" />
                                    )}
                                /> */}


                                {/* <Autocomplete
                                    multiple
                                    id="checkboxes-tags-demo"
                                    options={userDetailsList}
                                    fullWidth
                                    size='small'
                                    disableCloseOnSelect
                                    style={{ marginTop: 1 }}
                                    getOptionLabel={(option) => option.userName}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                            <Checkbox
                                                size='small'
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.userName}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label="Search User" placeholder="Search User" />
                                    )}
                                /> */}

                                {/* <input
                                    type='text' placeholder="Search User"
                                    className={classes.searchIcon}
                                    maxLength={255}
                                />
                                <FormControl component="fieldset" sx={{ marginTop: 1 }}>
                                    <FormGroup row>
                                        <Scrollbars autoHide
                                            autoHideTimeout={1000}
                                            autoHideDuration={10}
                                            autoHeight
                                            className={`${classes.textBoxStyle2} ${classes.scrollMargin}`}
                                            autoHeightMin={235}
                                            autoHeightMax={235}
                                            style={{ width: '100%' }}>
                                            {row?.map((r) => (
                                                <FormControlLabel
                                                    id="U_CU_paymentUpdates"
                                                    className={classes.userpermission}
                                                    control={<Checkbox size="small" name="disputeNotification" />}
                                                    label={<span className={classes.inputFontSize}>{r?.userName}</span>}
                                                />
                                            ))}
                                        </Scrollbars>
                                    </FormGroup>
                                </FormControl> */}
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box m={0} boxShadow={2} p={1} pl={2} pr={2} mt={1} borderRadius={1}>
                                <Typography variant='body2' bgcolor={'rgb(97 175 254 / 20%)'} color={'rgb(1, 41, 114)'} p={1} borderRadius={1} pl={2} mb={1}>
                                    <b>Assign to Client Code</b> {selectedClients?.length > 0 ? <span><b>: ({" Selected Client Code"} : {selectedClients.length} ) </b></span> : null}
                                </Typography>


                                <Card className={classes.boxBorder}>
                                    <Scrollbars autoHide
                                        autoHideTimeout={1000}
                                        autoHideDuration={10}
                                        autoHeight
                                        className={`${classes.textBoxStyle2} ${classes.scrollMargin}`}
                                        autoHeightMin={115}
                                        autoHeightMax={115}
                                        style={{ width: '100%' }}>
                                        <div className={`${classes.valueContainer}`} >
                                            {selectedClients?.length > 0 ?
                                                (
                                                    <>
                                                        {
                                                            selectedClients.map((v) => (
                                                                <Chip key={v} size="small" color="secondary" label={v.clientCode} onDelete={onDelete(v.clientCode)} />
                                                            ))
                                                        }
                                                    </>
                                                )
                                                :
                                                <Typography variant='body2' color={'rgb(1, 41, 114)'} mt={2} align='center'>
                                                    <InfoIcon fontSize='large' />
                                                    <br />
                                                    <b>Selected client code list</b>
                                                </Typography>
                                            }
                                        </div>
                                    </Scrollbars>
                                </Card>


                                <ClickAwayListener onClickAway={(e) => { handleClickAway(e, searchClientCode.length > 0) }}>
                                    <div style={{ display: 'flex' }}>
                                        <Autocomplete
                                            multiple size="small"
                                            loading={loading}
                                            filterOptions={(options) => options}
                                            className={classes.clientCodeBox}
                                            autoComplete={false}
                                            id="checkboxes-tags-demo"
                                            options={confirmedClients}
                                            classes={{ paper: classes.paper2 }}
                                            disableClearable
                                            value={selectedClients}
                                            open={ClientCodeOpen}
                                            renderTags={() => null}
                                            onChange={(e, selected) => { handleClientSelect(e, selected); }}
                                            onOpen={() => {
                                                setClientCodeOpen(true);
                                            }}
                                            onClose={(e: any, reason: AutocompleteCloseReason) => {
                                                if (reason === "toggleInput") {
                                                    setClientCodeOpen(false);
                                                }
                                            }}
                                            getOptionDisabled={(option) => option.clientCode}
                                            getOptionLabel={(option) => option.clientCode}
                                            getOptionSelected={(option, value) => (option.clientCode == value.clientCode)}
                                            renderOption={(option, { selected }) => (
                                                <span className={`${selected ? classes.GreenColor : classes.clientCodeStyle}`} >
                                                    {`${option.clientCode} -- ${option.clientName}`}
                                                </span>
                                            )}
                                            ListboxProps={
                                                {
                                                    style: {
                                                        maxHeight: '160px'
                                                    }
                                                }
                                            }
                                            renderInput={(params) => (
                                                <TextField {...params} fullWidth size="small"
                                                    value={searchClientCode} variant="outlined"
                                                    type='text' placeholder="Search Clients"
                                                    onChange={(e) => { setSearchClientCode(e.target.value); }}
                                                    onKeyDown={event => {
                                                        if (event.key === 'Enter') { handleSearchClient(event); handleClientOpen(phase); setClientCodeOpen(true); }
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        inputProps: {
                                                            ...params.inputProps,
                                                            maxLength: 256
                                                        },
                                                        classes: {
                                                            root: classes.inputFontSize,
                                                        },
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {loading ? <CircularProgress color="primary" size={15} /> : null}
                                                                {params.InputProps.endAdornment}
                                                                <InputAdornment position="end">
                                                                    <IconButton onClick={(_) => { handleClientOpen(phase); setClientCodeOpen(true); }}>
                                                                        <SearchIcon className={classes.IconSizeStyle} />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                                <Box display="flex" width="100%" className={classes.CheckBoxBorder}>
                                                                    <Box className={classes.ClientcheckBoxStyle} width="60%">
                                                                        <Checkbox size="small" disabled={loading || !confirmedClients?.length}
                                                                            checked={checkAll}
                                                                            onChange={checkAllChange}
                                                                            id="check-all"
                                                                        />
                                                                        <span style={{ fontSize: 13, marginLeft: '2px' }}><b>SELECT ALL</b></span>
                                                                    </Box>
                                                                    <Box className={classes.ClientcheckBoxStyle} width="40%">
                                                                        <Checkbox size="small" disabled={loading || !confirmedClients?.length}
                                                                            checked={deselectClient}
                                                                            onChange={(e) => { DeselectAllChange(e); }}
                                                                            id="check-all"
                                                                        />
                                                                        <span style={{ fontSize: 13 }}><b>DESELECT ALL</b></span>
                                                                    </Box >
                                                                </Box >
                                                                <Card className={classes.checkBoxClientPhase} style={{ padding: '7px' }}>
                                                                    <FormGroup row>
                                                                        <FormControlLabel
                                                                            style={{ marginRight: '13px' }}
                                                                            id="U_CU_FileAccess"
                                                                            className={classes.clientpermission}
                                                                            control={<Checkbox size="small" name="isFirstParty" value={FIRSTSTP} className={classes.notificationSeletct} checked={selectedPhase?.isFirstParty} onChange={(e) => { handlePhaseChange(e); }} />}
                                                                            label={<span className={classes.notificationPhaseStyle} ><b>1ST PARTY</b></span>}
                                                                        />
                                                                        <FormControlLabel
                                                                            style={{ marginRight: '13px' }}
                                                                            id="U_CU_FileAccess"
                                                                            className={classes.clientpermission}
                                                                            control={<Checkbox size="small" name="isFlatFee" value={PREC} className={classes.notificationSeletct} checked={selectedPhase?.isFlatFee} onChange={(e) => { handlePhaseChange(e); }} />}
                                                                            label={<span className={classes.notificationPhaseStyle} ><b>FLAT FEE</b></span>}
                                                                        />
                                                                        <FormControlLabel
                                                                            style={{ marginRight: '13px' }}
                                                                            id="U_CU_FileAccess"
                                                                            className={classes.clientpermission}
                                                                            control={<Checkbox size="small" name="isContingency" value={CONT} className={classes.notificationSeletct} checked={selectedPhase?.isContingency} onChange={(e) => { handlePhaseChange(e); }} />}
                                                                            label={<span className={classes.notificationPhaseStyle}><b>CONTINGENCY</b></span>}
                                                                        />
                                                                    </FormGroup>
                                                                </Card  >
                                                            </React.Fragment >
                                                        ),
                                                    }
                                                    }
                                                    className={classes.textBoxStyle}
                                                />
                                            )}
                                        />
                                    </div>
                                </ClickAwayListener >
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='success' size='small' startIcon={<CheckCircleOutlineIcon />} sx={{ borderRadius: 5 }}>Submit</Button>
                    <Button variant='contained' color='error' size='small' startIcon={<CancelIcon />} sx={{ borderRadius: 5, marginRight: 4 }}>Close</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}

export default NotificationPermission