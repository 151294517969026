import { Box, Button, Checkbox, Dialog, DialogContent, FormControl, FormControlLabel, FormGroup, Grid, Snackbar, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
// Icons
import SaveIcon from '@material-ui/icons/Save';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
// Models
import { useStyles } from './AgentActivityCSS';
import { usePost } from '../../../utils/apiHelper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { AllowOnlyAlphabetsNumbers } from '../../../constants/Constant';
import CustomizeControl from '../GsettingAgentActivity/CustomizeControl';
import { IAgentActivities } from '../../../models/AgentTemplate/IAgentActivities';
import { IAgentActivitiesPermission } from '../../../models/AgentTemplate/IClientTemplateDetails';

const CreateTemplate: React.FC<{ orderCount: number, onSave: () => void, ActivityList: IAgentActivities[] }> = (props) => {
    const classes = useStyles();
    const [isCreate, setIsCreate] = useState(false);
    const [open, setOpen] = useState<boolean>(false);
    const [actvityId, setActivityId] = useState<number>(0);
    const [nameError, setnameError] = useState<string>("");
    const [activityName, setActivityName] = useState<string>("");
    const [isCaptured, setIsCaptured] = useState<boolean>(false);
    const [activityParentId, setActivityParentId] = useState<number>(0);
    const [activityProcess, setActivityProcess] = useState<number>(0);
    const [descriptionError, setdescriptionError] = useState<string>("");
    const [activtyControlEnable, setActivityControlEnable] = useState(false);
    const [activityDescription, setActivitydescription] = useState<string>("");

    let getActivity: IAgentActivitiesPermission = {
        agentReport: false,
        caseCreatedReport: false,
        activityCountReport: false,
        includeInCount: false
    }

    const [localState, setLocalState] = useState(getActivity);
    const activitiesWithNone = [{ activityMapId: 0, activityName: "None", activityDescription: "None", displayOrder: 0, parentActivityName: null, operationType: 0 }, ...props?.ActivityList];

    const handleClickOpen = () => {
        setOpen(true);
    };


    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const UpdateMessage = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsCreate(false);
    };

    const handleClose = () => {
        setOpen(false);
        setIsCaptured(false);
        resetValues();
        setActivityControlEnable(false);
    };

    const handleGroupNameChange = (event, selected) => {
        setActivityParentId(selected?.activityId);
    }

    const HandleTempDescriptionVal = (e) => {
        const ActivityDescription = e.target.value;

        if (!ActivityDescription.match(AllowOnlyAlphabetsNumbers)) {
            setdescriptionError("");
            setActivitydescription(ActivityDescription); // only set when successful
        } else if (ActivityDescription === "") {
            setdescriptionError("Activity Description is Required");
        } else {
            setdescriptionError("Please enter characters only");
        }
    };

    function resetValues() {
        setnameError("");
        setActivityName("");
        setActivityProcess(0);
        setdescriptionError("");
        setActivitydescription("");
        setActivityControlEnable(false);
    }

    const validateActivityName = (e) => {
        const activityName = e.target.value;
        setIsCaptured(true);
        if (!AllowOnlyAlphabetsNumbers.test(activityName)) {
            (async () => {
                let checkActivityName = { "activityName": activityName };
                await usePost<any>("AgentUser/CheckActivityExists", checkActivityName).then(async (r) => {
                    if (r?.data[0]?.isActivityNameCheck) {
                        setActivityProcess(2);
                        setnameError("Activity already exists, Please try different Activity!");
                    } else {
                        setnameError("");
                        setActivityProcess(3);
                    }
                }).finally(() => {
                });
            })()
        } else if (!e.target.value?.trim()?.length) {
            setActivityProcess(0);
            setnameError("");
        } else {
            setActivityProcess(1);
            setnameError("Please enter characters only");
        }
    };

    const createAgentActivity = () => {
        (async () => {
            let request = {
                "activityName": activityName,
                "activityDescription": activityDescription,
                "activityParentId": activityParentId,
                "displayOrder": props?.orderCount + 1,
                "agentReport": localState?.agentReport,
                "caseCreatedReport": localState?.caseCreatedReport,
                "activityCountReport": localState?.activityCountReport,
                "includeInCount": localState?.includeInCount
            }
            await usePost<any>("AgentUser/CreateAgentActivity", request).then((r) => {
                setActivityId(r?.data[0]?.activityId);
            });
            props?.onSave();
            setIsCreate(true);
            setActivityControlEnable(true);
        })()
    }

    const handleChecked = (event) => {
        const name = event.target.name;
        setLocalState({
            ...localState,
            [name]: event.target.checked,
        });
    };

    return (
        <React.Fragment>
            <Button id="CreateTemplate_btn" size="small" startIcon={<NoteAddIcon />} variant="contained" color="primary" onClick={handleClickOpen} className={classes.btnManTemp}>
                Create New Activity
            </Button>

            <Dialog open={open} PaperProps={{ style: { borderRadius: 15 } }} classes={{ paper: classes.customizeDialogePaper }} TransitionComponent={Transition}
                onClose={handleClose} aria-labelledby="responsive-dialog-title" >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        CREATE AGENT ACTIVITIES TEMPLATE
                    </Typography>
                </DialogTitleHeader>
                <DialogContent >
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={6} >
                            <Typography variant='subtitle1' className={classes.activityName}>
                                <b>Activity Name</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <Typography variant='subtitle1' className={classes.activityName}>
                                <b>Activity Description</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <TextField id="CNT_Name"
                                size="small"
                                variant="outlined"
                                error={!!nameError}
                                name="activityName"
                                autoComplete="off"
                                helperText={nameError}
                                fullWidth margin="dense"
                                label="Enter Activity Name"
                                style={{ width: "97%" }}
                                InputLabelProps={{ classes: { root: classes.inputFontSize } }}
                                onChange={(e) => { setActivityName(e.target.value); validateActivityName(e); }}
                                InputProps={{
                                    classes: {
                                        root: `${activityProcess === 0 ? classes.blueBorderBottom : activityProcess === 1 || activityProcess === 2 ? classes.redBorderBottom : classes.greenBorderBottom}`,
                                        notchedOutline: `${activityProcess === 0 ? classes.blueBorderColor : activityProcess === 1 || activityProcess === 2 ? classes.redBorderColor : classes.greenBorderColor}`,
                                    },
                                }}
                                className={`${isCaptured && activityProcess === 0 ? classes.blueBorderBottom : activityProcess === 1 || activityProcess === 2 ? classes.textFieldBorderRed : classes.textFieldBorderGreen}`}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField id="CNT_Name"
                                variant="outlined"
                                fullWidth margin="dense"
                                autoComplete="off"
                                error={!!descriptionError}
                                helperText={descriptionError}
                                label="Enter Activity Description"
                                size="small" name="activityDescription"
                                onChange={(e) => { setActivitydescription(e.target.value); HandleTempDescriptionVal(e); }}
                                className={`${!!descriptionError ? classes.textFieldBorderRed : classes.blueBorderBottom}`}
                                InputProps={{
                                    classes: {
                                        root: `${!!descriptionError ? classes.redBorderBottom : classes.blueBorderBottom}`,
                                        notchedOutline: `${!!descriptionError ? classes.redBorderColor : classes.blueBorderColor}`,
                                    },
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} >
                            <Typography variant='subtitle1' className={classes.activityName}>
                                <b>Select Parent Activity</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} >
                            <Autocomplete
                                id="combo-box-demo"
                                size='small'
                                options={activitiesWithNone?.map(option => ({ activityId: option.activityMapId, activityName: option.activityName }))}
                                getOptionLabel={(option) => option.activityName}
                                className={classes.groupName}
                                getOptionSelected={(option, value) => option.activityName === value.activityName}
                                onChange={(e, selected) => { handleGroupNameChange(e, selected); }}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        {option.activityName}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => <TextField {...params} label="Select Parent Activity" variant="outlined" />}
                            />
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={7} >
                                <Box className={classes.userboxviewStyle}>
                                    <Typography variant="h6" className={classes.headerStyle}>
                                        {"Include in"}
                                    </Typography>
                                    <FormControl component="fieldset" className={classes.formControlStyle}>
                                        <FormGroup row>
                                            <FormControlLabel
                                                id="agentReport"
                                                className={classes.permission}
                                                control={<Checkbox size="medium" color="primary" name="agentReport" onClick={handleChecked} />}
                                                label={<Typography className={classes.activityName}>{"Agent Report"}</Typography>}
                                            />
                                            <FormControlLabel
                                                id="caseCreatedReport"
                                                className={classes.permission}
                                                control={<Checkbox size="medium" color="primary" name="caseCreatedReport" onClick={handleChecked} />}
                                                label={<Typography className={classes.activityName}>{"Case Created Report"}</Typography>}
                                            />
                                            <FormControlLabel
                                                id="activityCountReport"
                                                className={classes.permission}
                                                control={<Checkbox size="medium" color="primary" name="activityCountReport" onClick={handleChecked} />}
                                                label={<Typography className={classes.activityName}>{"Count Report"}</Typography>}
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item xs={6} sm={5}>
                                <Box className={classes.userboxviewStyle}>
                                    <Typography variant="h6" className={classes.headerStyle}>
                                        {"Include in"}
                                    </Typography>
                                    <FormControl component="fieldset" className={classes.formControlStyle}>
                                        <FormGroup row>
                                            <FormControlLabel
                                                id="includeInCount"
                                                className={classes.permission}
                                                control={<Checkbox size="medium" color="primary" name="includeInCount" onClick={handleChecked} />}
                                                label={<Typography className={classes.activityName}>{"Activity Count"}</Typography>}
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} >
                            <div className={classes.saveActivity}>
                                <Button id="CNT_CreateTemplate_Btn" size="small" className={classes.createtemplate} startIcon={<SaveIcon />} variant="contained" autoFocus color="primary" onClick={() => { createAgentActivity(); resetValues(); }} disabled={!activityParentId || activtyControlEnable || !!(descriptionError.length) || !!(nameError.length) || !activityName?.length || !activityDescription?.length}>
                                    Save Activity
                                </Button>
                            </div>
                        </Grid>

                        {activtyControlEnable ?
                            <Grid item xs={12} sm={12}>
                                <CustomizeControl activityId={actvityId} actionType={1} />
                            </Grid>
                            : null
                        }
                    </Grid>
                </DialogContent>
            </Dialog>

            <Snackbar className="snackBarStyle" open={isCreate} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={UpdateMessage}>
                <Alert onClose={UpdateMessage} severity="success" className="alertStyle">
                    Activity Created Successfully.
                </Alert>
            </Snackbar>
        </React.Fragment >
    );
}

export default CreateTemplate